import React, { FC, PropsWithChildren, ReactNode, ReactNodeArray } from 'react'

export interface IQuoteState {
    quoteState?: {
        quoteRef: string;
        premium: number;
        newPremium: number;
        createdAt: string;
        inceptionDate: string;
        days: number;
        circuit: number; //this is a number because the circuit ID is used in DB
        invoiceSent: boolean;
        cars: {
            carRowID: number;
            makeModel: string;
            reg: string;
            value: number;
            sumInsured: number;
        }[]
    }
    setQuoteState? : any; //this is a function
}


// Our global theme context with default values
export const QuoteContext = React.createContext<IQuoteState>({})

// Theme provider component with state
const QuoteProvider: FC<PropsWithChildren> = props => {
  const [quoteState, setQuoteState] = React.useState()
  const value = { quoteState, setQuoteState }

  return (
    <QuoteContext.Provider value={value}>
      {props.children}
    </QuoteContext.Provider>
  )
}

// Exports a ThemeProvider wrapper
export default ({ element }: {element: ReactNode | ReactNode[]}) => <QuoteProvider>{element}</QuoteProvider>
//export default QuoteProvider